import http from "./http_service";
import { get_api_url } from "../components/utils";

const api_endpoint = get_api_url() + "/metrics";

export function get_metrics(begin, end) {
    return http.get(`${api_endpoint}?begin=${begin}&end=${end}`);
}


export function get_metrics_category(id, begin, end) {
    return http.get(`${api_endpoint}/category/${id}?begin=${begin}&end=${end}`);
}
