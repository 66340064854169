import { Typography, Box, Grid, Tooltip, TableRow, TableCell } from "@mui/material";
import React from "react";
import { Clear, Check } from "@mui/icons-material";
import { toast } from "react-toastify";
import moment from "moment";

export function get_api_url() {
    // return `http://127.0.0.1:8000`;
    return "https://elvick.giize.com:6969";
}

export const OWNERS = [
    { value: "ELVICK", text: "ELVICK" },
    { value: "ELVIRE", text: "ELVIRE" },
    { value: "INCONNU", text: "INCONNU" },
    { value: "PIERRICK", text: "PIERRICK" },
];

// export function get_api_refresh_url() {
//     return `${get_root_url()}/auth/jwt/refresh/`;
// }

// export function get_api_create_url() {
//     return `${get_root_url()}/api/token/`;
// }

// export function openInNewTab(url) {
//     const newWindow = window.open(url, "_blank", "noopener,noreferrer");
//     if (newWindow) newWindow.opener = null;
// }

// export function display_equipe(equipe) {
//     if (!equipe)
//         return (
//             <span
//                 style={{
//                     backgroundColor: "black",
//                     textAlign: "center",
//                     color: "white",
//                     fontWeight: "bold",
//                     width: 20,
//                     minWidth: 20,
//                     display: "inline-block",
//                     marginRight: 10,
//                 }}
//             >
//                 ?
//             </span>
//         );
//     return (
//         <span
//             style={{
//                 backgroundColor: equipe.code_couleur,
//                 textAlign: "center",
//                 color: "white",
//                 fontWeight: "bold",
//                 width: 20,
//                 minWidth: 20,
//                 display: "inline-block",
//                 marginRight: 10,
//             }}
//         >
//             {equipe.lettre}
//         </span>
//     );
// }

// export function format_tel_number(tel_number) {
//     if (!tel_number) return "";
//     let formated_tel = tel_number.replaceAll(".", "");
//     formated_tel = formated_tel.replaceAll(" ", "");
//     formated_tel = formated_tel.replaceAll("-", "");
//     formated_tel = formated_tel.replaceAll("/", "");
//     formated_tel = formated_tel.substring(1);
//     return `+33${formated_tel}`;
// }

// export function format_tel_number_link(tel_number) {
//     let formated_tel = format_tel_number(tel_number);
//     return (
//         <a style={{ display: "inline-block" }} href={`tel:${formated_tel}`}>
//             {tel_number}
//         </a>
//     );
// }

// function padZero(str, len) {
//     len = len || 2;
//     var zeros = new Array(len).join("0");
//     return (zeros + str).slice(-len);
// }

// function hex2(c) {
//     c = Math.round(c);
//     if (c < 0) c = 0;
//     if (c > 255) c = 255;

//     var s = c.toString(16);
//     if (s.length < 2) s = "0" + s;

//     return s;
// }

// function color(r, g, b) {
//     return "#" + hex2(r) + hex2(g) + hex2(b);
// }

// export function shade(col, light) {
//     var r = parseInt(col.substr(1, 2), 16);
//     var g = parseInt(col.substr(3, 2), 16);
//     var b = parseInt(col.substr(5, 2), 16);

//     if (light < 0) {
//         r = (1 + light) * r;
//         g = (1 + light) * g;
//         b = (1 + light) * b;
//     } else {
//         r = (1 - light) * r + light * 255;
//         g = (1 - light) * g + light * 255;
//         b = (1 - light) * b + light * 255;
//     }

//     return color(r, g, b);
// }

// export function invertColor(hex, bw) {
//     if (hex.indexOf("#") === 0) {
//         hex = hex.slice(1);
//     }
//     // convert 3-digit hex to 6-digits.
//     if (hex.length === 3) {
//         hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
//     }
//     if (hex.length !== 6) {
//         throw new Error("Invalid HEX color.");
//     }
//     var r = parseInt(hex.slice(0, 2), 16),
//         g = parseInt(hex.slice(2, 4), 16),
//         b = parseInt(hex.slice(4, 6), 16);
//     if (bw) {
//         // https://stackoverflow.com/a/3943023/112731
//         return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
//     }
//     // invert color components
//     r = (255 - r).toString(16);
//     g = (255 - g).toString(16);
//     b = (255 - b).toString(16);
//     // pad each with zeros and return
//     return "#" + padZero(r) + padZero(g) + padZero(b);
// }

// export const POSTES = [
//     { id: "chef", value: "chef", text: "Chef", color: "#FFD700" },
//     { id: "operateur", value: "operateur", text: "Opérateur", color: "#C0C0C0" },
//     { id: "poseur", value: "poseur", text: "Poseur", color: "#cd7f32" },
// ];

// export const gregorian_fr = {
//     name: "gregorian_fr",
//     months: [
//         ["Janvier", "Jan"],
//         ["Février", "Fév"],
//         ["Mars", "Mar"],
//         ["Avril", "Avr"],
//         ["Mai", "Mai"],
//         ["Juin", "Juin"],
//         ["Juillet", "Juil"],
//         ["Août", "Aug"],
//         ["Septembre", "Sep"],
//         ["Octobre", "Oct"],
//         ["Novembre", "Nov"],
//         ["Décembre", "Déc"],
//     ],
//     weekDays: [
//         ["Samedi", "Sam"],
//         ["Dimanche", "Dim"],
//         ["Lundi", "Lun"],
//         ["Mardi", "Mar"],
//         ["Mercredi", "Mer"],
//         ["Jeudi", "Jeu"],
//         ["Vendredi", "Ven"],
//     ],
//     digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
//     meridiems: [
//         ["AM", "am"],
//         ["PM", "pm"],
//     ],
// };

// export function displayWithTooltip(value) {
//     return (
//         <Tooltip title={<Typography>{`${value ? value : ""}`}</Typography>}>
//             <div>{` ${value ? value : ""}`}</div>
//         </Tooltip>
//     );
// }

// export function display_row(title, value, align = "center", width = 170) {
//     if (!value) return;
//     return (
//         <TableRow>
//             <TableCell align="center" style={{ width: width, maxWidth: width }}>
//                 <Box fontWeight="fontWeightMedium">{title}</Box>
//             </TableCell>
//             <TableCell align={align} tyle={{ width: 50 }}>
//                 {value}
//             </TableCell>
//         </TableRow>
//     );
// }

// export function capitalize_first_letter(string) {
//     return string.charAt(0).toUpperCase() + string.slice(1);
// }

// export function displayWithIcon(value) {
//     if (value) {
//         return <Check style={{ color: "#5EC278" }} />;
//     }
//     return <Clear style={{ color: "#C25E5E" }} />;
// }
// export function displayField(fieldName, value) {
//     return (
//         <Grid container direction="row" alignItems="center">
//             <Grid item sx={{ mr: 1 }}>
//                 <Box fontWeight="fontWeightMedium">{fieldName} : </Box>
//             </Grid>
//             <Grid item>{value}</Grid>
//         </Grid>
//     );
// }

// export function formatDuration(duration) {
//     if (!duration) return "00:00:00";
//     let hours = Math.floor(duration.asHours());
//     let mins = Math.floor(duration.asMinutes()) - hours * 60;
//     if (hours < 10) {
//         hours = `0${hours}`;
//     }
//     if (mins < 10) {
//         mins = `0${mins}`;
//     }
//     return `${hours}:${mins}:00`;
// }

// export function compare_number(a, b) {
//     if (a > b) return 1;
//     else if (a < b) return -1;
//     else return 0;
// }

// export async function mark_as_done(param, commande, value = true) {
//     if (!commande) return;

//     let commande_to_save = {
//         id: commande?.id,
//     };

//     if (param === "fiche_chaponnage") {
//         commande_to_save["fiche_chaponnage_done"] = value;
//     } else if (param === "fiche_horaire") {
//         commande_to_save["fiche_horaire_done"] = value;
//     } else return;

//     try {
//         const res = await saveCommande(commande_to_save);
//         if (res.status < 200 || res.status >= 400) throw new Error("saveCommande() returned an error status code");
//     } catch (e) {
//         console.log(e);
//         toast.error("Impossible de mettre à jour la commande");
//     }
// }

// export const yes_no = (value) => {
//     return value ? "oui" : "non";
// };

// export const get_tarif = (commande) => {
//     if (!commande) return "";
//     if (commande.prix_status === 3) return "tarif influencé selon une autre commande";
//     if (commande.prix_status === 2) return "payé par le groupement";
//     if (commande.prix_status === 1) return commande.prix ? `${commande.prix}€` : "tarif spécial non mentionné";

//     return "grille tarifaire";
// };

// export const date_plus = (date, delta) => {
//     if (!date || !delta) return "";
//     return moment(date, "YYYY-MM-DD").add("days", delta).format("DD/MM/YYYY");
// };

// export const format_commandes_avis_chaponnage = (commandes) => {
//     let results = [];
//     commandes.map((commande) => {
//         let result = {
//             SOCIETE: commande?.client?.elevage?.replaceAll('"', ""),
//             NOM: commande?.client?.nom?.replaceAll('"', ""),
//             Groupement: commande?.client?.groupement?.nom?.replaceAll('"', ""),
//             "Date chaponnage": commande?.date ? moment(commande.date).format("DD/MM/YYYY HH:mm") : "",
//             Qté: commande?.quantite,
//             Souche: commande?.souche?.nom?.replaceAll('"', ""),
//             "Type MAJ": commande?.mise_a_jeun?.replaceAll('"', ""),
//             "Besoin ope": commande?.nb_ope,
//             "Besoin aide": commande?.nb_pos,
//             "Nb aide eleveur": commande?.nb_aide_eleveur,
//             "Heure client": commande?.date ? moment(commande.date).format("HH:mm") : "",
//             Ecrêtage: yes_no(commande?.ecretage),
//             Ebarbillonage: yes_no(commande?.ebarbillonage),
//             Injection: yes_no(commande?.injection),
//             "Prévoir antibio": yes_no(commande?.notre_antibio),
//             Observations: commande?.observations?.replaceAll('"', ""),
//             "Âge en jours": date_plus(commande?.date_mise_en_place, commande?.date),
//             "Âge min": date_plus(commande?.date_mise_en_place, commande?.souche?.age_min),
//             "Âge max": date_plus(commande?.date_mise_en_place, commande?.souche?.age_max),
//             "Tarif HT": get_tarif(commande),
//             "Heure départ": "",
//             "Temps route": "",
//             Km: "",
//             "Num camion": "",

//             Rue: commande?.client?.adresse_rue?.replaceAll('"', ""),
//             "Code postal": commande?.client?.adresse_code_postal?.replaceAll('"', ""),
//             Ville: commande?.client?.adresse_ville?.replaceAll('"', ""),

//             "Points cardinaux": commande?.client?.points_cardinaux,
//             Téléphone: commande?.client?.telephone,
//             Portable: commande?.client?.portable,
//             Mail: commande?.client?.email,
//         };

//         results.push(result);
//         return 0;
//     });
//     return results;
// };

// export const antibiotiques = ["Colampi", "Lincospectin", "Sodibio", "Autre (à renseigner dans l'observation chef d'équipe)"];

// export const analgesiques = ["Metacam", "Autre (à renseigner dans l'observation chef d'équipe)"];

// export const antibiotiques_eau = ["Amoxicilline", "Doxycycline", "Autre (à renseigner dans l'observation chef d'équipe)"];

// export const dosages = ["0,1 mL", "0,2 mL", "0,3 mL", "0,4 mL", "0,5 mL"];

// export const duree_pre_maj_labels = {
//     1: "12 heures",
//     2: "24 heures",
//     3: "36 heures",
//     4: "48 heures",
// };
// export const aliment_litiere_labels = {
//     1: "Beaucoup",
//     2: "Un peu",
//     3: "Absence",
// };

// export const nmb_labels = {
//     4: "Beaucoup",
//     3: "Moyen",
//     2: "Un peu",
//     1: "Normal",
// };
// export const luminosite_labels = {
//     4: "Très lumineux",
//     3: "Lumineux",
//     2: "Assombri",
//     1: "Sombre",
// };
// export const presence_eleveur_labels = {
//     1: "0%",
//     2: "25%",
//     3: "50%",
//     4: "75%",
//     5: "100%",
// };
// export const homogeniete_labels = {
//     1: "Homogène",
//     2: "Moyen",
//     3: "Hétérogène",
//     4: "Très hétérogène",
// };
// export const intervalle_labels = {
//     4: "Plus de 10%",
//     3: "De 5 à 10%",
//     2: "Moins de 5%",
//     1: "0%",
// };
// export const proprete_labels = {
//     1: "Propre",
//     2: "Moyen",
//     3: "Sale",
// };
// export const maj_labels = {
//     1: "Suffisante",
//     2: "Moyenne",
//     3: "Mauvaise",
// };
// export const litiere_labels = {
//     1: "Crouteuse",
//     2: "Humide",
//     3: "Sèche et aérée",
// };
// export const litiere_types = [
//     { value: "PAILLE_LONGUE", text: "Paille longue" },
//     { value: "PAILLE_HACHEE", text: "Paille hachée" },
//     { value: "PAILLE_BROYEE", text: "Paille broyée" },
//     { value: "COPEAUX", text: "Copeaux" },
//     { value: "SCIURE", text: "Sciure" },
//     { value: "ANAS_DE_LIN", text: "Anas de lin" },
//     { value: "AUTRES", text: "Autres" },
// ];
// export const litiere_statuts = [
//     { value: "INITIALE", text: "Initiale" },
//     { value: "REPAILLAGE", text: "Repaillage" },
// ];
// export const volaille_types = [
//     { value: "POULET", text: "Poulet" },
//     { value: "PINTADE", text: "Pintade" },
//     { value: "POULARDE", text: "Poularde" },
// ];
// export const volailles = ["POULET", "PINTADE", "POULARDE"];
// export const categories_souches = ["Légère", "Medium", "Lourde", "Autre"];
// export const checklist_types = ["APPEL", "CAMION", "CAMPING"];

// export const abreuvement_types = [
//     { value: "ABREUVOIRS", text: "Abreuvoirs" },
//     { value: "PIPETTES", text: "Pipettes" },
// ];
// export const reglement_types = [
//     { value: "CHEQUE", text: "Chèque" },
//     { value: "ESPECES", text: "Espèces" },
//     { value: "VIREMENT", text: "Virement" },
//     { value: "GROUPEMENT", text: "Groupement" },
//     { value: "AUTRES", text: "Autres" },
// ];
// export const mise_a_jeun_types = [
//     { value: "PAILLE", text: "Paille" },
//     { value: "CAISSE", text: "Caisse" },
//     { value: "FILET", text: "Filet" },
//     { value: "AUTRES", text: "Autres" },
// ];
// export function makeId(length) {
//     var result = "";
//     var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//     var charactersLength = characters.length;
//     for (var i = 0; i < length; i++) {
//         result += characters.charAt(Math.floor(Math.random() * charactersLength));
//     }
//     return result;
// }

// export const mois = [
//     { value: "01", text: "Janvier" },
//     { value: "02", text: "Février" },
//     { value: "03", text: "Mars" },
//     { value: "04", text: "Avril" },
//     { value: "05", text: "Mai" },
//     { value: "06", text: "Juin" },
//     { value: "07", text: "Juillet" },
//     { value: "08", text: "Août" },
//     { value: "09", text: "Septembre" },
//     { value: "10", text: "Octobre" },
//     { value: "11", text: "Novembre" },
//     { value: "12", text: "Décembre" },
// ];

// export const prix_status = [
//     { value: 0, text: "Grille tarifaire dans Chapi" },
//     { value: 1, text: "Prix spécial" },
//     { value: 2, text: "Prix du groupement, non communiqué au client" },
//     { value: 3, text: "Tarif influencé selon une commande" },
// ];

// export const emplumement_statuts = [
//     { value: "SOYEUX", text: "Soyeux" },
//     { value: "NORMAL", text: "Normal" },
//     { value: "HIRSUTE", text: "Hirsute" },
// ];

// export const comportement_statuts = [
//     { value: "CALME", text: "Calme" },
//     { value: "UN_PEU_NERVEUX", text: "Un peu nerveux" },
//     { value: "TRES_NERVEUX", text: "Très nerveux" },
// ];
// export const taille_gants = [
//     { value: "S", text: "S" },
//     { value: "M", text: "M" },
//     { value: "L", text: "L" },
// ];

// export const fin_elevage_mortalite_intervalle_labels = {
//     4: "Plus de 15%",
//     3: "De 10% à 15%",
//     2: "De 5% à 10%",
//     1: "Moins de 5%",
// };

// export const fin_elevage_rouge_intervalle_labels = {
//     6: "Plus de 25%",
//     5: "De 20% à 25%",
//     4: "De 15% à 20%",
//     3: "De 10% à 15%",
//     2: "De 5% à 10%",
//     1: "Moins de 5%",
// };

// export const fin_elevage_nervosite_intervalle_labels = {
//     3: "Nerveux",
//     2: "Normaux",
//     1: "Calme",
// };

// export const fin_elevage_boiterie_intervalle_labels = {
//     4: "Plus de 10%",
//     3: "De 5% à 10%",
//     2: "De 2% à 5%",
//     1: "Moins de 2%",
// };
