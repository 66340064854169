import React, { useState, useEffect } from "react";

import { Box, Grid, Typography, Skeleton, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { get_transaction, get_transactions, save_transaction } from "../../services/transaction_service";
import { ru } from "date-fns/locale";
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";
import moment from "moment";
import { get_categories } from "../../services/category_service";
import AddRuleDialog from "../rules/add_rule_dialog";
import TransactionsTable from "./transactions_table";

export default function Transactions() {
    const [transactions, set_transactions] = useState();

    useEffect(() => {
        async function fetch_transactions() {
            try {
                const { data: transactions_found } = await get_transactions();
                set_transactions(transactions_found);
            } catch (e) {
                console.log(e);
                toast.error("Impossible de récupérer les règles");
            }
        }

        document.title = `Transactions`;

        fetch_transactions();
    }, []);

    return (
        <Box sx={{ mx: 1 }}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Typography variant="h4">Transactions</Typography>
                </Grid>
                <Grid item md={12}>
                    <TransactionsTable transactions={transactions} />
                </Grid>
            </Grid>
        </Box>
    );
}
