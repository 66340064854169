import axios from "axios";


const axiosInstance = axios.create({
	headers: {
		"Content-Type": "application/json",
	},
});


export default {
	get: axiosInstance.get,
	post: axiosInstance.post,
	put: axiosInstance.put,
	delete: axiosInstance.delete,
};
