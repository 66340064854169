import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Link } from "react-router-dom";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        to={"/"}
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        ACCUEIL
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                        <MenuItem key={"Cartes"} onClick={handleCloseNavMenu}>
                            <Typography component={Link} to={"/cartes"} textAlign="center">
                                Cartes
                            </Typography>
                        </MenuItem>
                        <MenuItem key={"Catégories"} onClick={handleCloseNavMenu}>
                            <Typography component={Link} to={"/categories"} textAlign="center">
                                Catégories
                            </Typography>
                        </MenuItem>
                        <MenuItem key={"Rules"} onClick={handleCloseNavMenu}>
                            <Typography component={Link} to={"/rules"} textAlign="center">
                                Règles
                            </Typography>
                        </MenuItem>
                        <MenuItem key={"Statistiques"} onClick={handleCloseNavMenu}>
                            <Typography component={Link} to={"/metrics"} textAlign="center">
                                Statistiques
                            </Typography>
                        </MenuItem>
                        <MenuItem key={"import"} onClick={handleCloseNavMenu}>
                            <Typography component={Link} to={"/import"} textAlign="center">
                                Importer fichier CSV
                            </Typography>
                        </MenuItem>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
