import http from "./http_service";
import { get_api_url } from "../components/utils";

const api_endpoint = get_api_url() + "/transactions";

export function get_transactions() {
    return http.get(`${api_endpoint}`);
}
export function get_transaction(id) {
    return http.get(`${api_endpoint}/${id}`);
}

export function save_transaction(transaction) {
    if (transaction.id) {
        const body = { ...transaction };
        return http.put(`${api_endpoint}/${transaction.id}`, body);
    }

    return http.post(`${api_endpoint}/`, transaction);
}

export function delete_transaction(id) {
    return http.delete(`${api_endpoint}/${id}`);
}
