import http from "./http_service";
import { get_api_url } from "../components/utils";

const api_endpoint = get_api_url() + "/categories";

export function get_categories() {
    return http.get(`${api_endpoint}`);
}
export function get_category(id) {
    return http.get(`${api_endpoint}/${id}`);
}

export function save_category(category) {
    if (category.id) {
        const body = { ...category };
        return http.put(`${api_endpoint}/${category.id}`, body);
    }

    return http.post(`${api_endpoint}/`, category);
}

export function delete_category(id) {
    return http.delete(`${api_endpoint}/${id}`);
}
