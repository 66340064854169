import React, { useState, useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { get_rule, get_rules, save_rule } from "../../services/rule_service";
import { ru } from "date-fns/locale";
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";
import { get_categories } from "../../services/category_service";
import { TextInput } from "../common/TextInput";
import { SendAndArchiveOutlined } from "@mui/icons-material";
import { Button, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SelectInput } from "../common/SelectInput";
import { save_category } from "../../services/category_service";
import { OWNERS } from "../utils";

export default function AddCategoryDialog({ open, set_open, category_added_clbk }) {
    const { control, handleSubmit, reset } = useForm();

    const onSubmit = async (values) => {
        try {
            const res = await save_category({
                nom: values.nom,
                montant_mensuel_min: values.montant_mensuel_min,
                montant_mensuel_max: values.montant_mensuel_max,
            });
            if (res.status < 200 || res.status >= 400) throw new Error("save_category() returned an error status code");
            toast.success("Catégorie ajoutée !");
            set_open(false);
            category_added_clbk();
            reset();
        } catch (ex) {
            console.log(ex);
            toast.error(`Erreur dans l'enregistrement de la catégorie : ${ex}`);
        }
    };

    return (
        <Dialog open={open} onClose={() => set_open(false)}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Ajouter une catégorie</DialogTitle>
                <DialogContent>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <TextInput control={control} required fullWidth name="nom" label="Nom" />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextInput control={control} name="montant_mensuel_min" type="number" fullWidth label="Budget min mensuel" />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextInput control={control} name="montant_mensuel_max" type="number" fullWidth label="Budget max mensuel" />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            set_open(false);
                            reset();
                        }}
                    >
                        Annuler
                    </Button>
                    <Button type="submit">Ajouter la catégorie</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
