import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { AppBar, Avatar, Box, Container, CssBaseline, Drawer, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import React, { lazy, useEffect, useState } from "react";
import { Link, NavLink, Navigate, Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Rules from "./components/rules/rules"
import Transactions from "./components/transactions/transactions"
import Cartes from "./components/cartes/cartes"
import Categories from "./components/categories/categories"
import ResponsiveAppBar from "./components/app_bar"
import ImportCsvFile from "./components/import"
import "react-toastify/dist/ReactToastify.css";
import Metrics from "./components/metrics/metrics";

export default function App() {
    const navigate = useNavigate();

    return (
        <div style={{ width: "100%" }}>
                <ResponsiveAppBar />
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <Box element="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Container maxWidth="xl" disableGutters={true}>
                        <Routes>
                            <Route path="/" element={<Transactions />} />
                            <Route path="/cartes" element={<Cartes />} />
                            <Route path="/metrics" element={<Metrics />} />
                            <Route path="/rules" element={<Rules />} />
                            <Route path="/categories" element={<Categories />} />
                            <Route path="/import" element={<ImportCsvFile />} />
                        </Routes>
                    </Container>
                </Box>
            </Box>
        </div>
    );
}
