import http from "./http_service";
import { get_api_url } from "../components/utils";

const api_endpoint = get_api_url() + "/cartes";

export function get_cartes() {
    return http.get(`${api_endpoint}`);
}
export function get_carte(id) {
    return http.get(`${api_endpoint}/${id}`);
}

export function save_carte(carte) {
    if (carte.id) {
        const body = { ...carte };
        return http.put(`${api_endpoint}/${carte.id}`, body);
    }

    return http.post(`${api_endpoint}`, carte);
}

export function delete_carte(id) {
    return http.delete(`${api_endpoint}/${id}`);
}
