import React, { useState } from "react";
import { toast } from "react-toastify";

const ImportCsvFile = () => {
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (file) {
            toast.info("Chargement en cours...");
            try {
                const formData = new FormData();
                formData.append("csv_file", file, file.name);
                await fetch(`https://elvick.giize.com:6969/csv_file`, {
                    method: "POST",
                    body: formData,
                })
                    .then((response) => response.json())
                    .then((data) =>
                        toast.success(`Fichier importé !\nTransactions créées : ${data["created"]}\nTransactions ignorées : ${data["skipped"]}`)
                    );
            } catch (error) {
                console.error(error);
                toast.error(`Erreur dans l'import du fichier : ${error}`);
            }
        }
    };

    return (
        <div>
            <div>
                <label htmlFor="file" className="sr-only">
                    Choisir un fichier CSV
                </label>
                <input id="file" type="file" onChange={handleFileChange} accept="text/csv" />
            </div>

            {file && <button onClick={handleUpload}>Charger le fichier</button>}
        </div>
    );
};

export default ImportCsvFile;
