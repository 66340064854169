import React, { useEffect, useState } from "react";

import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { get_metrics } from "../../services/metrics_service";
import { get_transactions } from "../../services/transaction_service";
import { SelectInput } from "../common/SelectInput";
import TransactionsTable from "../transactions/transactions_table";
import CategoryMetric from "./category_metric";
export default function Metrics() {
    const [transactions, set_transactions] = useState();
    const [categories, set_categories] = useState();
    const [form_begin_values, set_form_begin_values] = useState([]);
    const [form_end_values, set_form_end_values] = useState([]);
    const [begin, set_begin] = useState();
    const [end, set_end] = useState();
    const [months, set_months] = useState(0);
    const [revenus, set_revenus] = useState(0);
    const [depenses, set_depenses] = useState(0);
    const [balance, set_balance] = useState(0);

    useEffect(() => {
        async function fetch_transactions() {
            try {
                let begin_values = [];
                let end_values = [];
                const { data: transactions_found } = await get_transactions();

                let date = moment(new Date(transactions_found[0].date)).startOf("month");
                const last_date = moment(new Date(transactions_found[transactions_found.length - 1].date)).endOf("month");
                while (date.isBefore(last_date)) {
                    begin_values.push({
                        value: date.startOf("month").format("DD/MM/YYYY"),
                        text: date.startOf("month").format("MMMM YYYY"),
                    });
                    end_values.push({
                        value: date.endOf("month").format("DD/MM/YYYY"),
                        text: date.endOf("month").format("MMMM YYYY"),
                    });
                    date = moment(date).add(1, "month");
                }
                set_form_begin_values(begin_values);
                set_form_end_values(end_values);
            } catch (e) {
                console.log(e);
                toast.error("Impossible de récupérer les transactions");
            }
        }

        document.title = `Statistiques`;

        fetch_transactions();
    }, []);

    const { control, handleSubmit, reset } = useForm();

    const watch_begin = useWatch({ control, name: "begin" });
    const watch_end = useWatch({ control, name: "end" });

    async function update_metrics(begin, end) {
        try {
            const { data: metrics } = await get_metrics(begin, end);
            set_transactions(metrics["transactions"]);

            let revenus_tmp = 0;
            let depenses_tmp = 0;
            metrics["transactions"]["data"].forEach(function (transac) {
                if (transac.montant > 0) {
                    revenus_tmp += transac.montant;
                } else {
                    depenses_tmp += transac.montant;
                }
            });

            set_revenus(parseInt(revenus_tmp));
            set_depenses(parseInt(depenses_tmp));
            set_balance(parseInt(revenus_tmp) + parseInt(depenses_tmp));
            set_categories(metrics["categories"]);
        } catch (err) {
            console.log(err);
            toast.error(`ERREUR ! Impossible de mettre à jour les statistiques : ${err}`);
        }
    }

    useEffect(() => {
        if (!watch_begin && !watch_end) return;
        let begin = watch_begin ? moment(watch_begin, "DD/MM/YYYY") : null;
        let end = watch_end ? moment(watch_end, "DD/MM/YYYY") : null;
        if (watch_begin && !watch_end) {
            end = moment(begin).endOf("month");
        }
        if (!watch_begin && watch_end) {
            begin = moment(end).startOf("month");
        }
        if (end.isBefore(begin)) {
            toast.error(`Erreur : ${begin.format("DD/MM/YYYY")} est avant ${end.format("DD/MM/YYYY")}`);
            return;
        }

        set_months(end.diff(begin, "months") + 1);

        begin = begin.format("DD/MM/YYYY");
        end = end.format("DD/MM/YYYY");
        set_begin(begin);
        set_end(end);
        update_metrics(begin, end);
    }, [watch_begin, watch_end]);

    return (
        <Box sx={{ mx: 1 }}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Typography variant="h4">Statistiques</Typography>
                </Grid>
                <Grid item md={12}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <SelectInput name="begin" control={control} label="Début" children={form_begin_values} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SelectInput name="end" control={control} label="Fin" children={form_end_values} />
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item md={4}>
                    <Typography variant="h5" style={{ color: "green" }}>
                        Revenus : {revenus} €
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Typography variant="h5" style={{ color: "red" }}>
                        Dépenses : {depenses} €
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Typography variant="h5" style={{ color: `${balance > 0 ? "green" : "red"}` }}>
                        Balance : {balance} €
                    </Typography>
                </Grid>
            </Grid>
            {categories && (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item md={12}>
                        <Typography variant="h5">Catégories</Typography>
                    </Grid>
                    {categories.map((category) => (
                        <Grid item md={months === 1 ? 3 : 12}>
                            <CategoryMetric begin={begin} end={end} category_metric={category} />
                        </Grid>
                    ))}
                </Grid>
            )}
            {transactions && (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item md={12}>
                        <Typography variant="h5">Transactions</Typography>
                    </Grid>
                    <Grid item md={12}>
                        <TransactionsTable transactions={transactions["data"]} height={350} />
                    </Grid>
                    <Grid item md={12}>
                        <Typography>Nombre de transactions : {transactions["nb_transactions"]}</Typography>
                        <Typography>Nombre de dépenses: {transactions["nb_depenses"]}</Typography>
                        <Typography>Somme moyenne d'une dépense: {transactions["average_amount_per_depenses"]} €</Typography>
                        <Typography>Nombre de gains: {transactions["nb_gains"]}</Typography>
                        <Typography>Somme moyenne d'un gain: {transactions["average_amount_per_gains"]} €</Typography>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}
