import React, { useState, useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { get_rule, get_rules, save_rule } from "../../services/rule_service";
import { ru } from "date-fns/locale";
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";
import { get_categories } from "../../services/category_service";
import { TextInput } from "../common/TextInput";
import { SendAndArchiveOutlined } from "@mui/icons-material";
import { Button, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SelectInput } from "../common/SelectInput";

export default function AddRuleDialog({ open, set_open, categories, rule_added_clbk, default_words = "" }) {
    const [parsed_categories, set_parsed_categories] = useState([]);

    useEffect(() => {
        if (categories) {
            set_parsed_categories(categories.map((category) => ({ ...category, value: category.nom, text: category.nom })));
        }
    }, [categories]);

    const { control, handleSubmit, reset } = useForm();

    const onSubmit = async (values) => {
        try {
            const res = await save_rule({
                keyword: values.keyword,
                category_id: categories.filter((category) => {
                    return category.nom === values.category;
                })[0].id,
            });
            if (res.status < 200 || res.status >= 400) throw new Error("save_rule() returned an error status code");
            toast.success("Règle ajoutée !");
            set_open(false);
            rule_added_clbk();
            reset();
        } catch (ex) {
            console.log(ex);
            toast.error(`Erreur dans l'enregistrement de la règle : ${ex}`);
        }
    };

    return (
        <Dialog open={open} onClose={() => set_open(false)}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Ajouter une règle</DialogTitle>
                <DialogContent>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <TextInput
                                control={control}
                                required
                                name="keyword"
                                fullWidth
                                label="Mots contenus dans l'intitulé"
                                defaultValue={default_words}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            {parsed_categories?.length > 0 && (
                                <SelectInput required name="category" control={control} label="Catégorie" children={parsed_categories} />
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            set_open(false);
                            reset();
                        }}
                    >
                        Annuler
                    </Button>
                    <Button type="submit">Ajouter la règle</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
