import React, { useState, useEffect } from "react";

import { Box, Grid, Typography, Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { delete_category, get_category, get_categories, save_category } from "../../services/category_service";
import { ru } from "date-fns/locale";
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";
import AddCategoryDialog from "./add_category_dialog";

export default function Categories() {
    const [categories, set_categories] = useState();
    const [open_add_category_dialog, set_open_add_category_dialog] = useState(false);
    const [category_to_delete, set_category_to_delete] = useState(null);

    async function fetch_categories() {
        try {
            const { data: categories_found } = await get_categories();
            set_categories(categories_found);
        } catch (e) {
            console.log(e);
            toast.error("Impossible de récupérer les catégories");
        }
    }
    useEffect(() => {
        document.title = `Catégories`;

        fetch_categories();
    }, []);

    const columns = [
        {
            field: "id",
            headerName: "ID",
            headerAlign: "center",
            align: "center",
            width: 50,
        },
        {
            field: "nom",
            headerName: "Nom",
            editable: true,
            headerAlign: "center",
            sortable: true,
            align: "center",
            width: 180,
        },
        {
            field: "montant_mensuel_min",
            sortable: true,
            editable: true,
            type: "number",
            headerName: "Montant mensuel min",
            headerAlign: "center",
            align: "center",
            width: 200,
            renderCell: ({ value }) => (value ? `${parseFloat(value).toFixed(2)} €` : ""),
        },
        {
            field: "montant_mensuel_max",
            sortable: true,
            editable: true,
            type: "number",
            headerName: "Montant mensuel max",
            headerAlign: "center",
            align: "center",
            renderCell: ({ value }) => (value ? `${parseFloat(value).toFixed(2)} €` : ""),
            width: 200,
        },
    ];

    const on_category_delete = async (category_id) => {
        try {
            await delete_category(category_id);
            set_category_to_delete(null);
            fetch_categories();
        } catch (e) {
            console.log(e);
            toast.error(`Erreur dans la suppression de la category : ${e}`);
        }
    };

    const handleProcessRowUpdate = async (new_row, old_row) => {
        try {
            const res = await save_category({
                id: new_row.id,
                nom: new_row.nom,
                montant_mensuel_min: new_row.montant_mensuel_min ? parseFloat(new_row.montant_mensuel_min).toFixed(2) : null,
                montant_mensuel_max: new_row.montant_mensuel_max ? parseFloat(new_row.montant_mensuel_max).toFixed(2) : null,
            });
            if (res.status < 200 || res.status >= 400) throw new Error("save_category() returned an error status code");
            const { data: category } = await get_category(new_row.id);
            return category;
        } catch (e) {
            console.log(e);
            toast.error(`Erreur dans la sauvegarde de la catégorie : ${e}`);
            return old_row;
        }
    };

    return (
        <Box sx={{ mx: 1 }}>
            <Grid container spacing={2}>
                <Grid item md={10}>
                    <Typography variant="h4">Catégories</Typography>
                </Grid>
                <Grid item md={2}>
                    <Button onClick={() => set_open_add_category_dialog(true)}>Ajouter une catégorie</Button>
                </Grid>

                <Grid item xs={12} md={12}>
                    {categories && (
                        <div style={{ height: 400, width: "100%" }}>
                            <Box>
                                <div style={{ height: 900, width: "100%" }}>
                                    <DataGridPro
                                        slots={{ toolbar: GridToolbar }}
                                        processRowUpdate={handleProcessRowUpdate}
                                        // localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        disableColumnFilter
                                        pageSize={150}
                                        disableSelectionOnClick
                                        rows={categories}
                                        columns={columns}
                                        onCellClick={(params, event) => {
                                            if (!event.ctrlKey) {
                                                event.defaultMuiPrevented = true;
                                                if (params.field === "id") set_category_to_delete(params.id);
                                            }
                                            return;
                                        }}
                                    />
                                </div>
                            </Box>
                        </div>
                    )}
                </Grid>
            </Grid>

            <AddCategoryDialog open={open_add_category_dialog} set_open={set_open_add_category_dialog} category_added_clbk={fetch_categories} />

            <Dialog open={category_to_delete} onClose={() => set_category_to_delete(null)}>
                <DialogTitle>Supprimer la catégorie</DialogTitle>
                <DialogActions>
                    <Button onClick={() => set_category_to_delete(null)}>Annuler</Button>
                    <Button onClick={() => on_category_delete(category_to_delete)}>Supprimer la catégorie</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
