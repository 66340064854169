import http from "./http_service";
import { get_api_url } from "../components/utils";

const api_endpoint = get_api_url() + "/rules";

export function get_rules() {
    return http.get(`${api_endpoint}`);
}
export function get_rule(id) {
    return http.get(`${api_endpoint}/${id}`);
}

export function save_rule(rule) {
    if (rule.id) {
        const body = { ...rule };
        return http.put(`${api_endpoint}/${rule.id}`, body);
    }

    return http.post(`${api_endpoint}`, rule);
}

export function delete_rule(id) {
    return http.delete(`${api_endpoint}/${id}`);
}
