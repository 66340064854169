import React, { useState, useEffect } from "react";

import { Box, Grid, Typography, Skeleton, CircularProgress, Button } from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { get_transaction, get_transactions, save_transaction } from "../../services/transaction_service";
import { ru } from "date-fns/locale";
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";
import moment from "moment";
import { get_categories } from "../../services/category_service";
import { get_metrics, get_metrics_category } from "../../services/metrics_service";
import AddRuleDialog from "../rules/add_rule_dialog";
import TransactionsTable from "../transactions/transactions_table";
import { useForm, useWatch } from "react-hook-form";
import { SelectInput } from "../common/SelectInput";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";

export default function CategoryMetric({ category_metric, begin, end, size = 150 }) {
    const [arc_color, set_arc_color] = useState("#52b202");
    const [x_axis_values, set_x_axis_values] = useState([]);
    const [y_axis_values, set_y_axis_values] = useState([]);
    const [nb_months, set_nb_months] = useState(0);
    const [gauge, set_gauge] = useState(0);
    const [no_threshold, set_no_threshold] = useState(!category_metric?.data?.montant_mensuel_max && !category_metric?.data?.montant_mensuel_min);

    const [is_montant_max, set_is_montant_max] = useState(category_metric?.data?.montant_mensuel_max ? true : false);
    const [restant, set_restant] = useState("");

    useEffect(() => {
        if (!category_metric) return;
        const is_montant_max = category_metric?.data?.montant_mensuel_max ? true : false;
        async function get_category_metric() {
            try {
                let moment_date = moment(begin, "DD/MM/YYYY").startOf("day");
                let moment_end = moment(end, "DD/MM/YYYY").endOf("day");
                set_nb_months(Math.ceil(moment(end, "DD/MM/YYYY").endOf("month").diff(moment(begin, "DD/MM/YYYY").startOf("month"), "months", true)));
                const tmp_gauge = category_metric.data.montant_mensuel_max
                    ? category_metric.data.montant_mensuel_max
                    : category_metric.data.montant_mensuel_min;
                set_gauge(tmp_gauge);
                const restant_tmp = parseFloat(tmp_gauge - category_metric.amount_per_month).toFixed(2);

                if (category_metric?.data?.montant_mensuel_max && restant_tmp < 0) {
                    set_restant(<span style={{ color: "red" }}>(dépassé de {restant_tmp * -1} €)</span>);
                } else if (category_metric?.data?.montant_mensuel_max && restant_tmp > 0) {
                    set_restant(<span style={{ color: "green" }}>(reste {restant_tmp} €)</span>);
                } else if (category_metric?.data?.montant_mensuel_min && restant_tmp > 0) {
                    set_restant(<span style={{ color: "red" }}>(manque {restant_tmp * -1} €)</span>);
                }

                let x_axis = [];
                let y_axis = [];
                while (moment_date.isSameOrBefore(moment_end)) {
                    x_axis.push(new Date(moment_date.year(), moment_date.month(), 1));
                    const { data: metrics_category } = await get_metrics_category(
                        category_metric.data.id,
                        moment_date.startOf("month").format("DD/MM/YYYY"),
                        moment_date.endOf("month").format("DD/MM/YYYY")
                    );
                    y_axis.push(metrics_category["total_amount"]);
                    moment_date.add(1, "month");
                }
                set_x_axis_values(x_axis);
                set_y_axis_values(y_axis);
            } catch (err) {
                toast.error(`Erreur : ${err}`);
            }
        }
        if (!category_metric?.data?.montant_mensuel_max && !category_metric?.data?.montant_mensuel_min) {
            set_arc_color("#52b202");
        } else if (is_montant_max) {
            if (category_metric.amount_per_month >= category_metric.data.montant_mensuel_max) {
                set_arc_color("#fc030f");
            } else if (category_metric.amount_per_month >= category_metric.data.montant_mensuel_max * 0.8) {
                set_arc_color("#fc9d03");
            }
        } else {
            if (category_metric.amount_per_month <= category_metric.data.montant_mensuel_max * 0.8) {
                set_arc_color("#fc9d03");
            } else if (category_metric.amount_per_month <= category_metric.data.montant_mensuel_max) {
                set_arc_color("#fc030f");
            }
        }
        get_category_metric();
    }, [category_metric, begin, end]);

    return category_metric ? (
        <Box>
            <Typography variant="h6">
                {category_metric.data.nom} {restant}
            </Typography>
            <Typography variant="h7">Dépense moyenne par mois</Typography>
            <Gauge
                width={size}
                height={size}
                value={category_metric.amount_per_month}
                valueMax={gauge}
                sx={{
                    [`& .${gaugeClasses.valueText}`]: {
                        fontSize: parseInt(size / 10),
                        transform: "translate(0px, 0px)",
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                        fill: arc_color,
                    },
                }}
                text={({ value, valueMax }) => `${value} ${valueMax ? ` / ${valueMax}\n${is_montant_max ? "max" : "min"}` : ""}`}
            />

            {nb_months > 1 && (
                <LineChart
                    height={300}
                    grid={{ horizontal: true }}
                    series={[
                        {
                            data: y_axis_values,
                            area: true,
                        },
                    ]}
                    margin={{
                        top: 10,
                        bottom: 20,
                    }}
                    yAxis={[
                        {
                            colorMap: {
                                type: "piecewise",
                                thresholds: [
                                    no_threshold
                                        ? 10000
                                        : category_metric.data.montant_mensuel_max
                                        ? category_metric.data.montant_mensuel_max
                                        : category_metric.data.montant_mensuel_min,
                                ],
                                colors: ["green", "red"],
                            },
                        },
                    ]}
                    xAxis={[
                        {
                            scaleType: "time",
                            data: x_axis_values,
                            tickLabelInterval: (value, _) => {
                                return value.getDate() === 1;
                            },
                            valueFormatter: (value) => moment(value).format("MMMM YYYY"),
                        },
                    ]}
                />
            )}
        </Box>
    ) : (
        <span></span>
    );
}
