import React, { useState, useEffect } from "react";

import { Box, Grid, Typography, Skeleton, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { get_transaction, get_transactions, save_transaction } from "../../services/transaction_service";
import { ru } from "date-fns/locale";
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";
import moment from "moment";
import { get_categories } from "../../services/category_service";
import AddRuleDialog from "../rules/add_rule_dialog";

export default function TransactionsTable({ transactions, height = 900 }) {
    const [categories, set_categories] = useState([]);
    const [categories_name_only, set_categories_name_only] = useState([]);
    const [add_rule_from_transac, set_add_rule_from_transac] = useState(null);

    useEffect(() => {
        async function fetch_categories() {
            try {
                const { data: categories_found } = await get_categories();
                set_categories(categories_found);
                set_categories_name_only(categories_found.map((category) => category.nom));
            } catch (e) {
                console.log(e);
                toast.error("Impossible de récupérer les règles");
            }
        }

        fetch_categories();
    }, []);

    const columns = [
        {
            field: "id",
            headerName: "ID",
            headerAlign: "center",
            align: "center",
            width: 50,
        },
        {
            field: "date",
            headerName: "Date",
            headerAlign: "center",
            sortable: true,
            type: "date",
            valueGetter: (value) => (value ? moment(value).toDate() : null),
            renderCell: ({ value }) => (value ? moment(value).format("DD/MM/YYYY") : ""),
            align: "center",
            width: 130,
        },
        {
            field: "intitule",
            headerName: "Intitulé",
            headerAlign: "center",
            sortable: true,
            width: 400,
        },
        {
            field: "type",
            editable: true,
            type: "singleSelect",
            valueOptions: ["VIREMENT", "PRELEVEMENT", "CARTE_BANCAIRE", "RETRAIT", "CHEQUE", "INCONNU"],
            headerName: "Type",
            headerAlign: "center",
            align: "center",
            width: 150,
        },
        {
            field: "montant",
            headerName: "Montant",
            headerAlign: "center",
            sortable: true,
            type: "number",
            align: "center",
            renderCell: ({ value }) => `${parseFloat(value).toFixed(2)} €`,
        },
        {
            field: "payeur",
            sortable: true,
            editable: true,
            type: "singleSelect",
            valueOptions: ["ELVIRE", "ELVICK", "PIERRICK", "INCONNU"],
            headerName: "Payeur",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "category",
            sortable: true,
            editable: true,
            headerName: "Catégorie",
            headerAlign: "center",
            type: "singleSelect",
            valueOptions: categories_name_only,
            align: "center",
            valueGetter: (value) => (value?.nom ? value.nom : ""),
            width: 150,
        },
        {
            field: "commentaire",
            editable: true,
            headerName: "Commentaire",
            headerAlign: "center",
            width: 150,
        },
    ];

    const handleProcessRowUpdate = async (new_row, old_row) => {
        try {
            let new_cat = [];
            if (typeof new_row.category === "string" || new_row.category instanceof String) {
                new_cat = categories.filter((category) => {
                    return category.nom === new_row.category;
                });
            } else if (new_row.category) {
                new_cat = categories.filter((category) => {
                    return category.nom === new_row.category.nom;
                });
            }
            const res = await save_transaction({
                id: new_row.id,
                commentaire: new_row.commentaire === null ? "" : new_row.commentaire,
                type: new_row.type,
                payeur: new_row.payeur,
                category_id: new_cat.length === 0 ? null : new_cat[0].id,
            });
            if (res.status < 200 || res.status >= 400) throw new Error("saveCommande() returned an error status code");
            const { data: transaction } = await get_transaction(new_row.id);
            return transaction;
        } catch (e) {
            console.log(e);
            toast.error(`Erreur dans la sauvegarde de la règle : ${e}`);
            return old_row;
        }
    };

    return (
        <Box sx={{ mx: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    {transactions && (
                        <Box>
                            <div style={{ height: height, width: "100%" }}>
                                <DataGridPro
                                    slots={{ toolbar: GridToolbar }}
                                    processRowUpdate={handleProcessRowUpdate}
                                    // localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    disableColumnFilter
                                    pageSize={150}
                                    disableSelectionOnClick
                                    rows={transactions}
                                    columns={columns}
                                    onCellClick={(params, event) => {
                                        if (!event.ctrlKey) {
                                            event.defaultMuiPrevented = true;
                                            if (params.field === "id") set_add_rule_from_transac(params.row.intitule);
                                        }
                                        return;
                                    }}
                                />
                            </div>
                        </Box>
                    )}
                </Grid>
            </Grid>

            <AddRuleDialog
                open={add_rule_from_transac}
                set_open={set_add_rule_from_transac}
                default_words={add_rule_from_transac}
                categories={categories}
                rule_added_clbk={() => {}}
            />
        </Box>
    );
}
