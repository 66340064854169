import React, { useState, useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { delete_rule, get_rule, get_rules, save_rule } from "../../services/rule_service";
import { ru } from "date-fns/locale";
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";
import { get_categories } from "../../services/category_service";
import { TextInput } from "../common/TextInput";
import { SendAndArchiveOutlined } from "@mui/icons-material";
import { Button, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SelectInput } from "../common/SelectInput";
import AddRuleDialog from "./add_rule_dialog";

export default function Rules() {
    const [rules, set_rules] = useState();
    const [categories, set_categories] = useState([]);
    const [categories_name_only, set_categories_name_only] = useState([]);
    const [open_add_rule_dialog, set_open_add_rule_dialog] = useState(false);
    const [rule_to_delete, set_rule_to_delete] = useState(null);

    async function fetch_rules() {
        try {
            const { data: rules_found } = await get_rules();
            set_rules(rules_found);
        } catch (e) {
            console.log(e);
            toast.error("Impossible de récupérer les règles");
        }
    }
    useEffect(() => {
        async function fetch_categories() {
            try {
                const { data: categories_found } = await get_categories();
                set_categories(categories_found.map((category) => ({ ...category, value: category.nom, text: category.nom })));
                set_categories_name_only(categories_found.map((category) => category.nom));
            } catch (e) {
                console.log(e);
                toast.error("Impossible de récupérer les règles");
            }
        }

        document.title = `Règles`;
        fetch_categories();

        fetch_rules();
    }, []);

    const columns = [
        {
            field: "id",
            headerName: "ID",
            headerAlign: "center",
            align: "center",
            width: 50,
        },
        {
            field: "keyword",
            headerName: "Mot clé",
            headerAlign: "center",
            editable: true,
            sortable: true,
            align: "center",
            width: 300,
        },
        {
            field: "category",
            sortable: true,
            editable: true,
            headerName: "Catégorie",
            headerAlign: "center",
            type: "singleSelect",
            valueOptions: categories_name_only,
            align: "center",
            valueGetter: (value) => (value?.nom ? value.nom : ""),
            width: 200,
        },
    ];
    const handleProcessRowUpdate = async (new_row, old_row) => {
        try {
            let new_cat;
            if (typeof new_row.category === "string" || new_row.category instanceof String) {
                new_cat = categories.filter((category) => {
                    return category.nom === new_row.category;
                });
            } else {
                new_cat = categories.filter((category) => {
                    return category.nom === new_row.category.nom;
                });
            }
            const res = await save_rule({
                id: new_row.id,
                keyword: new_row.keyword,
                category_id: new_cat[0].id,
            });
            if (res.status < 200 || res.status >= 400) throw new Error("save_rule() returned an error status code");
            const { data: rule } = await get_rule(new_row.id);
            return rule;
        } catch (e) {
            console.log(e);
            toast.error(`Erreur dans la sauvegarde de la règle : ${e}`);
            return old_row;
        }
    };
    const on_rule_delete = async (rule_id) => {
        try {
            await delete_rule(rule_id);
            set_rule_to_delete(null);
            fetch_rules();
        } catch (e) {
            console.log(e);
            toast.error(`Erreur dans la suppression de la règle : ${e}`);
        }
    };

    return (
        <Box sx={{ mx: 1 }}>
            <Grid container spacing={2}>
                <Grid item md={10}>
                    <Typography variant="h4">Règles</Typography>
                </Grid>
                <Grid item md={2}>
                    <Button onClick={() => set_open_add_rule_dialog(true)}>Ajouter une règle</Button>
                </Grid>

                <Grid item xs={12} md={12}>
                    {rules && (
                        <div style={{ height: 400, width: "100%" }}>
                            <Box>
                                <div style={{ height: 900, width: "100%" }}>
                                    <DataGridPro
                                        slots={{ toolbar: GridToolbar }}
                                        processRowUpdate={handleProcessRowUpdate}
                                        disableColumnFilter
                                        pageSize={150}
                                        disableSelectionOnClick
                                        rows={rules}
                                        columns={columns}
                                        onCellClick={(params, event) => {
                                            if (!event.ctrlKey) {
                                                event.defaultMuiPrevented = true;
                                                if (params.field === "id") set_rule_to_delete(params.id);
                                            }
                                            return;
                                        }}
                                    />
                                </div>
                            </Box>
                        </div>
                    )}
                </Grid>
            </Grid>

            <AddRuleDialog open={open_add_rule_dialog} set_open={set_open_add_rule_dialog} categories={categories} rule_added_clbk={fetch_rules} />

            <Dialog open={rule_to_delete} onClose={() => set_rule_to_delete(null)}>
                <DialogTitle>Supprimer la règle</DialogTitle>
                <DialogActions>
                    <Button onClick={() => set_rule_to_delete(null)}>Annuler</Button>
                    <Button onClick={() => on_rule_delete(rule_to_delete)}>Supprimer la règle</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
