import React, { useState, useEffect } from "react";

import { Box, Grid, Typography, Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { delete_carte, get_carte, get_cartes, save_carte } from "../../services/carte_service";
import { ru } from "date-fns/locale";
import { DataGridPro, GridActionsCellItem, GridToolbarContainer, GridToolbarExport, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";
import AddCarteDialog from "./add_carte_dialog";

export default function Cartes() {
    const [cartes, set_cartes] = useState();
    const [open_add_carte_dialog, set_open_add_carte_dialog] = useState(false);
    const [carte_to_delete, set_carte_to_delete] = useState(null);

    async function fetch_cartes() {
        try {
            const { data: cartes_found } = await get_cartes();
            set_cartes(cartes_found);
        } catch (e) {
            console.log(e);
            toast.error("Impossible de récupérer les règles");
        }
    }
    useEffect(() => {
        document.title = `Cartes`;

        fetch_cartes();
    }, []);

    const columns = [
        {
            field: "id",
            headerName: "ID",
            headerAlign: "center",
            align: "center",
            width: 50,
        },
        {
            field: "owner",
            headerName: "Propriétaire",
            editable: true,
            headerAlign: "center",
            type: "singleSelect",
            valueOptions: ["ELVIRE", "ELVICK", "PIERRICK", "INCONNU"],
            sortable: true,
            align: "center",
            width: 130,
        },
        {
            field: "number",
            sortable: true,
            editable: true,
            headerName: "Numéro de fin",
            headerAlign: "center",
            align: "center",
            width: 150,
        },
    ];

    const on_carte_delete = async (carte_id) => {
        try {
            await delete_carte(carte_id);
            set_carte_to_delete(null);
            fetch_cartes();
        } catch (e) {
            console.log(e);
            toast.error(`Erreur dans la suppression de la carte : ${e}`);
        }
    };

    const handleProcessRowUpdate = async (new_row, old_row) => {
        try {
            const res = await save_carte({
                id: new_row.id,
                owner: new_row.owner,
                number: new_row.number,
            });
            if (res.status < 200 || res.status >= 400) throw new Error("save_carte() returned an error status code");
            const { data: carte } = await get_carte(new_row.id);
            return carte;
        } catch (e) {
            console.log(e);
            toast.error(`Erreur dans la sauvegarde de la carte : ${e}`);
            return old_row;
        }
    };

    return (
        <Box sx={{ mx: 1 }}>
            <Grid container spacing={2}>
                <Grid item md={10}>
                    <Typography variant="h4">Cartes</Typography>
                </Grid>
                <Grid item md={2}>
                    <Button onClick={() => set_open_add_carte_dialog(true)}>Ajouter une carte</Button>
                </Grid>

                <Grid item xs={12} md={12}>
                    {cartes && (
                        <div style={{ height: 400, width: "100%" }}>
                            <Box>
                                <div style={{ height: 900, width: "100%" }}>
                                    <DataGridPro
                                        slots={{ toolbar: GridToolbar }}
                                        processRowUpdate={handleProcessRowUpdate}
                                        // localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        disableColumnFilter
                                        pageSize={150}
                                        disableSelectionOnClick
                                        rows={cartes}
                                        columns={columns}
                                        onCellClick={(params, event) => {
                                            if (!event.ctrlKey) {
                                                event.defaultMuiPrevented = true;
                                            }
                                            set_carte_to_delete(params.id);
                                            return;
                                        }}
                                    />
                                </div>
                            </Box>
                        </div>
                    )}
                </Grid>
            </Grid>

            <AddCarteDialog open={open_add_carte_dialog} set_open={set_open_add_carte_dialog} carte_added_clbk={fetch_cartes} />

            <Dialog open={carte_to_delete} onClose={() => set_carte_to_delete(null)}>
                <DialogTitle>Supprimer la carte</DialogTitle>
                <DialogActions>
                    <Button onClick={() => set_carte_to_delete(null)}>Annuler</Button>
                    <Button onClick={() => on_carte_delete(carte_to_delete)}>Supprimer la carte</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
